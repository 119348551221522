import React from "react";

import Layout from "../../components/Layout";

export default class MetaIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <section className="FaqPage">
          Meta
        </section>
      </Layout>
    );
  }
}
